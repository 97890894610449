import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)
const Nav = ({ open }) => (
  <nav
    style={{
      float: 'right'
    }}
  >
      <ul style={{ listStyle: `none`, float: `right` }}>
        <ListLink to="about">About</ ListLink>
        <li style={{ display: `inline-block`, marginRight: `1rem` }}><a href="https://www.linkedin.com/company/wallace-creative-llc">Linkedin</a></li>
      </ul>
  </nav>
)

Nav.propTypes = {
  open: PropTypes.bool,
  mobile: PropTypes.bool,
}

Nav.defaultProps = {
  open: false,
  mobile: false,
}

export default Nav