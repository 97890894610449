import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ open }) => (
  <footer style={{position: 'fixed',
    bottom: '0',
    padding: '.75rem',
    background: 'rgba(0,0,0,.9)',
    color: '#fff',
    borderTop: '#fff solid 3px',
    borderRight: '#fff solid 3px'
    }}>
    © {new Date().getFullYear()}, Wallace Creative
  </footer>
)

Footer.propTypes = {
  open: PropTypes.bool,
}

Footer.defaultProps = {
  open: false,
}

export default Footer