import { Link } from "gatsby"
import PropTypes from "prop-types"
import Nav from "./nav"
import React from "react"

const Header = ({ siteTitle , children}) => (
  <header
    style={{
      background: `white`,
      marginBottom: `1.45rem`,
    }}
  >
    <div className="container"
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h3 style={{ margin: 0,  display: `inline` }}>
        <Link
          to="/"
          style={{
            color: `#2b2b2b`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h3>
      <Nav />
    </div>
    {children}
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Wallace Creative`,
}

export default Header